import React from "react"

const Title = () => {
  return (
    <div className="mt-0 mb-8">
      <h2 className="text-white uppercase" style={{ fontFamily: `Montserrat` }}>
        Контакты
      </h2>
    </div>
  )
}

export default Title

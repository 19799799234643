import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"

import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"

import Hero from "../components/hero"
import MyMap from "../components/map"
import Form from "../components/form"
import Contact from "../components/contact"
import Title from "../components/title"
import Gallery from "../components/gallery"
import GalleryInfo from "../components/gallery-info"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Деревянные срубы, строительство домов, каркасные дома, фундамент, бани из бревна" />
      <Hero />
      <section className="mt-24 grid grid-cols-1 md:grid-cols-2">
        <Gallery />
        <GalleryInfo />
      </section>
      <section>
        <Form />
      </section>
      <section className="mt-8">
        <Title />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <MyMap />
          <Contact />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

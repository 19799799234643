import React, { useState } from "react"

import "../components/layout.css"
import ImgCmp from "../components/imgCmp"

import i1 from "../images/karkasdom.jpg"
import i2 from "../images/dervyandom.jpg"
import i3 from "../images/kirpichdom.jpg"
import i4 from "../images/banya.jpg"

const Gallery = () => {
  let sliderArr = [
    <ImgCmp
      src={i1}
      text={"Каркасные дома"}
      link={"/karkasnoe-stroitelstvo"}
    />,
    <ImgCmp src={i2} text={"Деревянные дома"} link={"/woodhouse"} />,
    <ImgCmp src={i3} text={"Кирпичные дома"} link={"/kirpichnie-doma"} />,
    <ImgCmp
      src={i4}
      text={"Деревянные бани"}
      link={"/bani-iz-rublennogo-brevna"}
    />,
  ]

  const [x, setX] = useState(0)

  const goLeft = () => {
    x === 0 ? setX(-100 * (sliderArr.length - 1)) : setX(x + 100)
  }
  const goRight = () => {
    x === -100 * (sliderArr.length - 1) ? setX(0) : setX(x - 100)
  }
  return (
    <>
      <div className="slider ">
        {sliderArr.map((item, index) => {
          return (
            <div
              key={index}
              className="slide"
              style={{ transform: `translateX(${x}%)` }}
            >
              {item}
            </div>
          )
        })}
        <button id="goLeft" onClick={goLeft}>
          <span style={{ fontSize: `20px`, color: `#cbd5e0` }}>&lt;</span>
        </button>
        <button id="goRight" onClick={goRight}>
          <span style={{ fontSize: `20px`, color: `#cbd5e0` }}>&gt;</span>
        </button>
      </div>
    </>
  )
}

export default Gallery

import React from "react"

import { FiPhone } from "react-icons/fi"
import { MdPlace } from "react-icons/md"
import { HiOutlineMail } from "react-icons/hi"

const Contacts = () => {
  return (
    <div className="flex flex-col align-center justify-center pt-16 md:pt-2 ml-8">
      <span className="flex flex-row" style={{ fontFamily: `Montserrat` }}>
        <MdPlace size={25} className="text-white" />
        <p className="text-white pl-4">
          Марий Эл, г.Йошкар-Ола,<br></br> ул. Панфилова, 33А, оф.210
        </p>
      </span>
      <span className="flex flex-row" style={{ fontFamily: `Montserrat` }}>
        <FiPhone size={25} className="text-white" />
        <a href="tel:+79677570025" className="text-white pl-4 pb-4">
          +7 967 757-00-25
        </a>
      </span>
      <span className="flex flex-row" style={{ fontFamily: `Montserrat` }}>
        <HiOutlineMail size={25} className="text-white" />
        <p className="text-white pl-4">sale@fs-tech.ru</p>
      </span>
    </div>
  )
}

export default Contacts

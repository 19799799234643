import React from "react"
import { Link } from "gatsby"

const GalleryInfo = () => {
  return (
    <div
      className="text-white flex flex-col align-center justify-center mt-8 md:ml-8 md:mt-0"
      style={{ fontFamily: `Montserrat` }}
    >
      <h3 style={{ fontFamily: `Montserrat` }}>Какой дом строим?</h3>

      <Link to="/woodhouse" style={{ textDecoration: `none`, color: `white` }}>
        <ul className="ml-0 mt-2">
          <li>Дом из дерева</li>
        </ul>
      </Link>

      <Link
        to="/kirpichnie-doma"
        style={{ textDecoration: `none`, color: `white` }}
      >
        <ul className="ml-0 mt-2">
          <li>Дом из кирпича</li>
        </ul>
      </Link>
      <Link
        to="/bani-iz-rublennogo-brevna"
        style={{ textDecoration: `none`, color: `white` }}
      >
        <ul className="ml-0 mt-2">
          <li>Баня из дерева</li>
        </ul>
      </Link>
      <Link
        to="/karkasnoe-stroitelstvo"
        style={{ textDecoration: `none`, color: `white` }}
      >
        <ul className="ml-0 mt-2">
          <li>Каркасное строительство</li>
        </ul>
      </Link>
    </div>
  )
}

export default GalleryInfo

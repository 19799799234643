import React from "react"

import wood from "../images/sl5.jpg"

const Hero = () => {
  return (
    <>
      <div
        className="w-full bg-cover bg-center"
        style={{
          height: `32rem`,
          backgroundImage: `url(${wood})`,
          // filter: `brightness(50%) `,
        }}
      >
        <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
          <div className="">
            <h1
              className="text-white font-semibold uppercase text-3xl md:text-3xl lg:text-4xl xl:text-4xl m-0"
              style={{ fontFamily: `Montserrat` }}
            >
              {" "}
              ФС-Технологии
            </h1>
            <p
              className="m-0 pt-4"
              style={{
                fontFamily: `Montserrat`,
                color: `#F1F1F2`,
              }}
            >
              Строительство и проектирование<br></br> для комфортной жизни
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero

import React from "react"
import { Link } from "gatsby"

const ImgCmp = ({ src, text, link }) => {
  console.log(text)
  let imgStyle = {
    width: 100 + "%",
    height: "auto",
    filter: `brightness(50%)`,
    objectFit: `fill`,
  }
  return (
    <div className="relative ">
      <Link to={link} style={{ textDecoration: `none`, color: `white` }}>
        <img
          src={src}
          alt={"slider"}
          style={imgStyle}
          className="m-0 p-0"
        ></img>
        <h3
          className="text-white absolute text-xl md:text-2xl ml-16 md:ml-20"
          style={{
            top: `45%`,
            textTransform: `uppercase`,
            // transform: `translateY(50%)`,
            fontFamily: `Montserrat`,
          }}
        >
          {text}
        </h3>
      </Link>
    </div>
  )
}

export default ImgCmp
